<template lang="pug">
    .relative
        label.text-xs.ml-1(v-if="label") {{ label }}
        textarea( 
            class="h-100 w-full p-2 caret-slate-300 border rounded block border-slate-300"
            @input="onValue"
            v-model="text_input"
        )
</template>

<script>
export default {
    name: "TextArea",
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            text_input: ''
        }
    },
    methods: {
        onValue() {
            this.$emit('value', this.text_input)
        }
    },
    watch: {
        value: {
            handler(val) {
                this.text_input = val 
            },
            immediate: true,
        }
    }
}
</script>