<template lang="pug">
    div
        table.table-auto.border-collapse.border.border-slate-400.w-full.bg-white
            table-header(:list="USERS_LIST_TABLE_HEADERS")
            tbody
                template(v-for="item in users")
                    tr(:key="item._id")
                        td.border.border-slate-300.w-20.p-2
                            template(v-if="item.photo && item.photo.path")
                                div.border.rounded-full.w-16.h-16.bg-cover(
                                    :style="`background-image: url(${base_url}/${item.photo.path})`" 
                                    @click="onImage({name: `${item._id}-photo`})"
                                )
                            template(v-else)
                                span(@click="onImage({name: `${item._id}-photo`})") Нет фото
                            modal(:name="`${item._id}-photo`")
                                p {{ item._id }}
                                .p-5
                                    choose-file.mb-3(:label="FORMS_LABELS.user_photo" @value="photo = $event" :value="Array.from(photo)")
                                    regular-button.w-32(:value="BUTTONS_VALUES.save" @click="onImageChange({ id: item._id, modal_name: `${item._id}-photo` })")
                                
                        td.text-sm.font-thin.border.border-slate-300.text-center.p-1
                            template(v-if="name_edit_id !== item._id")
                                p.cursor-pointer(@click="onField({ id: item._id, field: 'name' })") {{ item.name ? capitalizeFirstLetter({ text: item.name }) : '' }} &#9998;
                            template(v-else)
                                edit-input-wrapper( @cancel="onCancelEdit('name_edit_id')" @accept="onFieldEdit({ id: item._id, value: name_edit_value, field: 'name' })" )
                                    text-area(:value="item.name" @value="name_edit_value = $event")
                        
                        td.text-sm.font-thin.border.border-slate-300.text-center.p-1
                            template(v-if="lastname_edit_id !== item._id")
                                p.cursor-pointer(@click="onField({ id: item._id, field: 'lastname' })") {{ item.lastname ? capitalizeFirstLetter({ text: item.lastname }) : '' }} &#9998;
                            template(v-else)
                                edit-input-wrapper( @cancel="onCancelEdit('lastname_edit_id')" @accept="onFieldEdit({ id: item._id, value: lastname_edit_value, field: 'lastname' })" )
                                    text-area(:value="item.lastname" @value="lastname_edit_value = $event")
                        
                        td.text-sm.font-thin.border.border-slate-300.text-center.p-1
                            template(v-if="patronymic_edit_id !== item._id")
                                p.cursor-pointer(@click="onField({ id: item._id, field: 'patronymic' })") {{ item.patronymic ? capitalizeFirstLetter({ text: item.patronymic }) : '' }} &#9998;
                            template(v-else)
                                edit-input-wrapper( @cancel="onCancelEdit('patronymic_edit_id')" @accept="onFieldEdit({ id: item._id, value: patronymic_edit_value, field: 'patronymic' })" )
                                    text-area(:value="item.patronymic" @value="patronymic_edit_value = $event")
                        
                        td.text-sm.font-thin.border.border-slate-300.text-center.p-1
                            span {{ item.email }}
                        
                        td.text-sm.font-thin.border.border-slate-300.text-center.p-1
                            span {{ item.role }}
                        
                        td.text-sm.font-thin.border.border-slate-300.text-center
                            switcher-style-one.m-auto(
                                @on-switch="onStatusChange(item)"
                                :status="+item.status ? true : false"
                                :title="{on: SWITCHERS_VALUES.active, off: SWITCHERS_VALUES.blocked}"
                            )

                        td.text-xs.font-thin.border.border-slate-300.text-center {{ item.created_at | moment("dddd, MMMM Do YYYY") }}
                        
                        td.text-sm.font-thin.border.border-slate-300.text-center.text-gray-500 
                            inline-svg.m-auto(
                                :src="ICONS.bin" 
                                @click="() => { if (deleteFilter) {onDeleteConfirmation(item)} }"
                                :fill-opacity="deleteFilter ? 1 : 0.25"
                            )
        button(@click="onLoadMore") Загрузить еще
</template>

<script>
//Components
import SwitcherStyleOne from '@/components/common/switchers/style-1';
import TextArea from '@/components/common/forms/text-area';
import EditInputWrapper from '@/components/common/forms/edit-wrapper';
import TableHeader from '@/components/common/tables/table-header';
import ChooseFile from '@/components/common/forms/choose-file';
import RegularButton from '@/components/common/buttons/regular-button';
//Store
// import { FilesNameSpace, FilesActionTypes, FilesGetterTypes, FilesMutationTypes } from '@/store/files/types';
import { UsersNameSpace, UsersActionTypes, UsersGetterTypes, UsersMutationTypes } from '@/store/users/types';
import { RootGetterTypes } from '@/store/types';
//libs
import moment from 'moment';
//Helpers
import { capitalizeFirstLetter } from '@/helpers/text-transform';
//Configs
import { USERS_LIST_TABLE_HEADERS, SWITCHERS_VALUES, BUTTONS_VALUES, FORMS_LABELS } from '@/configs/names.js';
import { ICONS } from '@/configs/icons';
//Mixins
import { deleteConfirmation, closeModalDialog, _openModal_, _closeModal_ } from '@/mixins/modals';
import { _onFileUpload_ } from '@/mixins/files';

export default {
    name: "UsersComponent",
    mixins: [deleteConfirmation, closeModalDialog, _openModal_, _closeModal_, _onFileUpload_],
    components: {
        'switcher-style-one': SwitcherStyleOne,
        'text-area': TextArea,
        'edit-input-wrapper': EditInputWrapper,
        'table-header': TableHeader,
        'choose-file': ChooseFile,
        'regular-button': RegularButton,
    },
    data() {
        return {
            name_edit_id: '',
            name_edit_value: '',
            lastname_edit_id: '',
            lastname_edit_value: '',
            patronymic_edit_id: '',
            patronymic_edit_value: '',
            delete_user_id: null,
            photo: [],
            photo_edit_id: '',
            currentPage: 0,
            perPage: 50,
        }
    },
    computed: {
        ICONS() {
            return ICONS
        },
        SWITCHERS_VALUES() {
            return SWITCHERS_VALUES
        },
        BUTTONS_VALUES() {
            return BUTTONS_VALUES
        },
        FORMS_LABELS() {
            return FORMS_LABELS
        },
        USERS_LIST_TABLE_HEADERS() {
            return USERS_LIST_TABLE_HEADERS
        },
        users() {
            return this.$store.getters[`${UsersNameSpace}/${UsersGetterTypes.GetUsers}`]
        },
        base_url() {
            return process.env.VUE_APP_BASE_URL
        },
        deleteFilter() {
            return this.$store.getters[`${RootGetterTypes.GetDeleteFilterStatus}`]
        },
    },
    methods: {
        moment() {
            return moment()
        },
        capitalizeFirstLetter({text}) {
            return capitalizeFirstLetter({text})
        },
        onLoad({ per_page, page }) {            
            this.$store.dispatch(`${UsersNameSpace}/${UsersActionTypes.GetUsers}`, {params: { per_page, page }})
        },
        onLoadMore() {
            this.currentPage = this.currentPage + 1
            this.onLoad({ per_page: this.perPage, page: this.currentPage })
        },
        onStatusChange({ _id, status }) {
            let status_value = Number(status)
            status_value = !status_value
            this.onUserChange({ id: _id, value: {status: status_value} })
        },

        async onFieldEdit({ id, value, field }) {
            await this.onUserChange({ id: id, value: { [field]: value } })
            this[`${field}_edit_id`] = ''
        },
        onField({ id, field }) {
            this[`${field}_edit_id`] = id
        },
        onCancelEdit(value) {
            this[value] = ''
        },
        onImage({ name }) {
            this._openModal_({ name })
        },
        async onImageChange({ id, modal_name }) {
            await this._onFileUpload_({ field: 'photo' })
            await this.onUserChange({ id: id, value: { photo: this._loadedFile_  } })
            this._closeModal_({ name: modal_name })
        },
        async onUserChange({ id, value }) {
            await this.$store.dispatch(`${UsersNameSpace}/${UsersActionTypes.EditUser}`, { id, value })
        },
        async onDeleteConfirmation({_id}) {
            this.delete_user_id = _id
            this.deleteConfirmation({ confirm: 'onDeleteUser' })
            
        },
        async onDeleteUser() {
            await this.$store.dispatch(`${UsersNameSpace}/${UsersActionTypes.DeleteUser}`, { id: this.delete_user_id }) 
            this.closeModalDialog()
            this.delete_user_id = null
        }
    },
    mounted() {
        this.onLoad({ per_page: this.perPage, page: this.currentPage })
    },
    beforeDestroy() {
        this.$store.commit(`${UsersNameSpace}/${UsersMutationTypes.SetEmptyUsers}`)
    }
}
</script>