<template lang="pug">
    .container
        slot
        .container.p-1.flex.justify-end.gap-3
            button.block.text-red.text-red-500.font-medium.cursor-pointer.underline(@click="$emit('cancel')") {{ cancelText }}
            button.inline-block.text-accent.font-medium.cursor-pointer.underline(@click="$emit('accept')") {{ acceptText }}
</template>

<script>
export default {
    name: "InputEditWrapper",
    props: {
        cancelText: {
            type: String,
            default: 'отмена',
        },
        acceptText: {
            type: String,
            default: 'сохранить',
        },
    },
}
</script>