<template lang="pug">
    div
        filters
        users-list
</template>

<script>
//Components
import Filters from '@/components/common/filters';
import RegularButton from '@/components/common/buttons/regular-button';
import UsersList from '@/components/views/users/';
//Config
import { BUTTONS_VALUES } from '@/configs/names.js';

export default {
    name: "CategoriesListPage",
    components: {
        'filters': Filters,
        'regular-button': RegularButton,
        'users-list': UsersList,
    },
    computed: {
        BUTTONS_VALUES() {
            return BUTTONS_VALUES
        },
    },
    mounted() {
    },
    methods: {
    }
}
</script>