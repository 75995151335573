<template lang="pug">
    thead
        tr.h-10
            template(v-for="(item, i) in list")
                th.text-xs.font-medium.border.border-slate-300(:key="i") {{ item }}
</template>

<script>
export default {
    name: "CommonTableHeader",
    props: {
        list: {
            type: Array,
            default: () => [],
        }
    }
}
</script>